* {
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: black;
}

#redwood-app h1 {
  /* font-size: 45px;
  font-weight: bold;
  font-style: italic;
  line-height: 1.22; */
}

#redwood-app h2,
.ant-modal-root h2 {
  font-size: 38px;
  font-weight: bold;
  line-height: 1.24;
  margin-bottom: 0;
}

#redwood-app h3 {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.23;
}

#redwood-app h4 {
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.21;
}

#redwood-app h5 {
  font-size: 20px;
  font-style: italic;
  line-height: 1.2;
}

/*
#redwood-app p {
font-size: 20px;
  font-weight: 300;
  line-height: 1.7;
} */

.ant-modal-root .pre-selected-floor .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background:#FF631B;
}
